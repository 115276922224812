import React from "react";
import { graphql } from "gatsby";
import ContentMDX from "../components/ContentMDX";
import Layout from "../components/Layout";
import DateFormatter, { DateContainer} from "../components/DateContainer";


const WorkMDX = ({ data: { page } }) => {
  const {startDate, endDate, position} = page.frontmatter
  return (
    <Layout {...page.frontmatter} subtitle={position}>
      <DateContainer>
        <DateFormatter date={startDate}/> - <DateFormatter date={endDate} nullValue="Current"/>
      </DateContainer>
      {/* <ContentMDX source={page.body} /> */}
    </Layout>
  );
};

export default WorkMDX;

export const pageQuery = graphql`
  query WorkMDX($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        profile
        position
        startDate
        endDate
        shortUrl
        featuredImage
      }
    }
  }
`;
